import type { OpenPopupEntry } from '../stores/popups'
import type { MaybeElementRef } from '@vueuse/core'
import type { MaybeRefOrGetter } from 'vue'

/**
 * @example
 * {
 * // A function to close the popup
 * closeCallback: () => void
 * // Whether the scroll should be
 * // locked when the popup is open (default: `true`)
 * lockScroll?: boolean
 *
 * // A template element ref to close
 * // the popup when clicked outside of it
 * // MUST BE SET IN ORDER TO ENABLE THIS FUNCTIONALITY
 * closeOnClickOutside?: MaybeElementRef
 *
 * // A list of template element refs
 * // to ignore when checking if the
 * // click was outside the popup
 * ignoreElements?: MaybeElementRef[]
 *
 * // Whether the scroll should be
 * // unlocked when the popup is closed
 * // and there are no other scroll-locking popups
 * // (default: `true`)
 * //
 * // if the option is disabled (maybe in case
 * // you use animations and need to ensure
 * // the scroll is unlocked only after the
 * // animation is finished, the function
 * // to try to unlock the scroll needs
 * // to be called manually)
 * autoUnlockScroll?: boolean
 * }
 */
export type PopupOptions = Omit<OpenPopupEntry, 'lockScroll'> & {
    /**
     * Whether the scroll should be locked when this popup is open.
     * @default true
     */
    lockScroll?: MaybeRefOrGetter<boolean | undefined>
} & {
    /**
     * A template element ref, which when clicked outside of it,
     * the popup will be closed
     *
     * MUST BE SET IN ORDER TO ENABLE THIS FUNCTIONALITY
     */
    closeOnClickOutside?: MaybeElementRef
    /**
     * A list of template element refs
     * to ignore when checking if the
     * click was outside the popup
     */
    ignoreElements?: MaybeRefOrGetter<MaybeElementRef[]>
    /**
     * Whether the scroll should be
     * unlocked when the popup is closed
     * and there are no other scroll-locking popups
     *
     * if the option is disabled (maybe in case
     * you use animations and need to ensure
     * the scroll is unlocked only after the
     * animation is finished, the function
     * to try to unlock the scroll needs
     * to be called manually)
     * @default true
     */
    autoUnlockScroll?: boolean
    /**
     * When to automatically close the popup
     * - `route-change` - close the popup every time the route changes
     * - `route-name-change` - close the popup only when the route changes to a different route (ignores query and hash changes)
     * @default undefined
     */
    autoCloseOn?: 'route-change' | 'route-name-change'
    /**
     * A promise, which is resolved after the popup open animation is finished.
     * If it resolves to `true`, it means that the opening was successful & we should
     * proceed to lock the scroll. If not, the opening was interrupted by closing.
     */
    openingPromise?: Ref<Promise<boolean> | null>
    /**
     * A promise, which is resolved after the popup close animation is finished.
     * If it resolves to `true`, it means that the closing was successful & we should
     * proceed to unlock the scroll. If not, the closing was interrupted by another opening.
     */
    closingPromise?: Ref<Promise<boolean> | null>
}

/**
 * A composable meant to manage the opening and closing of popups.
 * This ensures that the scroll is locked and unlocked when a popup requires it, as well as adds the ability
 * to close the popup with the ESC key, outside click etc.
 *
 * @param isOpen a ref that indicates whether the popup is open or not (should be the same ref as the one used in the `v-model` binding)
 * @param popup a function reference to close the popup or an object with the close callback and other options
 * @param callbacks optional callbacks to be called (in the watcher) when the popup is opened or closed
 */
export default function useManagePopupOpening(isOpen: Ref<boolean | undefined>, popup: PopupOptions | (() => void), callbacks: OpenCloseCallbacks | null = null) {
    if (import.meta.server) return

    const { saveOpenPopup, cleanupOpenPopup, tryToUnlockScroll } = usePopupsStore()

    // if popup options were provided
    if (typeof popup !== 'function') {

        // Option - Close on Outside Click
        if (popup.closeOnClickOutside) {
            onClickOutside(popup.closeOnClickOutside, () => (isOpen.value = false), {
                ignore: popup.ignoreElements,
            })
        }

        // Option - Close on Route Change
        if (popup.autoCloseOn === 'route-change' || popup.autoCloseOn === 'route-name-change') {
            const route = useRoute()
            if (popup.autoCloseOn === 'route-change') {
                watch(() => route.fullPath, (newValue, oldValue) => (isOpen.value = false))
            } else if (popup.autoCloseOn === 'route-name-change') {
                watch(() => route.name, (newValue, oldValue) => (isOpen.value = false))
            }
        }

    }

    /**
     * Manage handling of the popup opening and closing.
     * This makes sure that the right callbacks are called and the popup
     * is registered as an open popup when opened and unregistered when closed.
     */
    async function handleOpenStateChange(isOpen: boolean | undefined, isInitial?: boolean) {
        if (isOpen) {
            const openingPromise: Promise<boolean> | null = typeof popup !== 'function' && popup.openingPromise ? toValue(popup.openingPromise) : null
            // opening callback
            callbacks?.onOpening?.(openingPromise ?? undefined)

            // add another open popup to the currently open popups
            saveOpenPopup(typeof popup === 'function'
                ? popup
                : {
                    closeCallback: popup.closeCallback,
                    lockScroll: toValue(popup.lockScroll),
                }
            )

            if (openingPromise) {
                const shouldOpen = await openingPromise
                if (!shouldOpen) return
            }

            callbacks?.onOpened?.()
        } else if (!isInitial) {
            const closingPromise: Promise<boolean> | null = typeof popup !== 'function' && popup.closingPromise ? toValue(popup.closingPromise) : null

            // closing callback
            callbacks?.onClosing?.(closingPromise ?? undefined)

            if (closingPromise) {
                const didCloseCompletely = await closingPromise
                if (!didCloseCompletely) return
            }

            // cleanup (make sure we don't leave the popup in the open popups array if we closed it using the v-model)
            // doesn't do anything if the modal is already not present in the open popups array
            const wasPopupPresent = cleanupOpenPopup(typeof popup === 'function' ? popup : popup.closeCallback)
            if (!wasPopupPresent) return

            // try to auto unlock the scroll if the functionality wasn't disabled
            if (typeof popup === 'function' || ((popup.lockScroll || popup.lockScroll === undefined) && popup.autoUnlockScroll !== false)) {
                tryToUnlockScroll()
            }

            // closed callback
            callbacks?.onClosed?.()
        }
    }
    handleOpenStateChange(isOpen.value, true)
    watch(isOpen, (val) => handleOpenStateChange(val))

    onUnmounted(() => {
        // cleanup (make sure we don't leave the popup in the open popups array
        // if the component is no longer mounted in the DOM -> can happen during route change, for example)
        cleanupOpenPopup(typeof popup === 'function' ? popup : popup.closeCallback)

        // update the scroll locked variable to unlock the scroll if needed
        tryToUnlockScroll()
    })
}

interface OpenCloseCallbacks {
    /**
     * A callback function that is called when the popup starts opening.
     * This is called before the opening promise is resolved.
     *
     * If the opening promise is available, it is provided as an argument to the callback.
     *
     * It is not certain that the opening will be successful at this point. This
     * only marks the start of the opening process.
     *
     * To perform some actions when the opening is cancelled, you can check the promise result,
     * or return a function, which will be called if the opening is cancelled.
     */
    onOpening?: (openingPromise?: Promise<boolean>) => void | (() => void)
    /**
     * A callback function that is called after the popup is opened.
     * This is called after the opening promise is resolved.
     * This means that you can be sure that the opening animation is finished and the popup is visible.
     */
    onOpened?: () => void
    /**
     * A callback function that is called when the popup starts closing.
     * This is called before the closing promise is resolved.
     *
     * If the closing promise is available, it is provided as an argument to the callback.
     *
     * It is not certain that the closing will be successful at this point. This
     * only marks the start of the closing process.
     *
     * To perform some actions when the closing is cancelled, you can check
     * the promise result, or return a function, which will be called if the closing
     * is cancelled.
     */
    onClosing?: (closingPromise?: Promise<boolean>) => void | (() => void)
    /**
     * A callback function that is called after the popup is closed.
     * If a closing promise is provided, this callback is called after the promise is resolved.
     * This means that you can be sure that the closing animation is finished and the popup is no longer visible.
     */
    onClosed?: () => void
}
